















































































































































































































































import { Component } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Button from '@/components/Button.vue'
import Loading from '@/components/Loading.vue'
import Dialog from '@/components/Dialog.vue'
import Content from '@/components/Content.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import { getUser } from '@/services/Identity'
import { uploadFile, deleteFromUrl, getDownloadUrl } from '@/services/Storage'
import { Identity } from '@/models/Identity'
import { UserConverter } from '@/models/converters/UserConverter'
import { showNotification } from '@/services/NotificationService'
import { mixins } from 'vue-class-component'
import UserMixin from '@/mixins/UserMixin.vue'

@Component({
  components: {
    Header,
    Button,
    Loading,
    Dialog,
    Content,
    ImageUpload
  }
})
export default class Account extends mixins(UserMixin) {
  loading: boolean = true
  savingUser: boolean = false
  savingPassword: boolean = false
  deleteModalActive: boolean = false
  deletingAccount: boolean = false

  avatar: File

  newPassword: string = ''
  repeatNewPassword: string = ''

  previousPhotoUrl: string = ''

  countries: { [id: string]: string } = {}

  async created() {
    // Apply data from state (firebase user) => single source of truth
    this.user.email = this.mainStore.identity.email
    this.user.name = this.mainStore.identity.name
    this.user.photoUrl = this.mainStore.identity.photoUrl

    this.loading = false
  }

  async updateUser() {
    this.savingUser = true
    try {
      await getUser().updateEmail(this.user.email)
    } catch (error) {
      // Requires recent login => redirect to login
      this.handleRequireRecentLogin(error)
      return
    }

    if (this.avatar) {
      if (this.previousPhotoUrl) {
        // Previous photo exists => delete
        try {
          await deleteFromUrl(this.previousPhotoUrl)
        } catch (ex) {
          // No previous file exists => nothing to do
        }
      }

      try {
        const photoPath = `/users/${this.user!.id}/${this.avatar.name}`
        await uploadFile(photoPath, this.avatar)
        this.user.photoUrl = await getDownloadUrl(photoPath)
      } catch (ex) {
        // TODO show error
      }
    }

    await this.user.reference
      .withConverter(new UserConverter())
      .set(this.user, { merge: true })
    await this.applyIdentity()

    this.savingUser = false
    this.showSuccessNotification()
  }

  async applyIdentity() {
    const identity: Identity = {
      id: this.user.id,
      name: this.user.name,
      photoUrl: this.user.photoUrl,
      email: this.user.email
    }
    await this.mainStore.setIdentity(identity)
  }

  canChangePassword(): boolean {
    return (
      this.newPassword !== '' &&
      this.repeatNewPassword !== '' &&
      this.newPassword.length >= 6 &&
      this.newPassword === this.repeatNewPassword
    )
  }

  async changePassword() {
    this.savingPassword = true
    // Delete user
    try {
      await getUser().updatePassword(this.newPassword)
      this.newPassword = this.repeatNewPassword = ''
    } catch (error) {
      // Requires recent login => redirect to login
      this.handleRequireRecentLogin(error)
      return
    }
    this.savingPassword = false
    this.showSuccessNotification()
  }

  imageChanged(file: File) {
    this.previousPhotoUrl = this.user.photoUrl
    this.user.photoUrl = URL.createObjectURL(file)
    this.avatar = file
  }

  async deleteAccount() {
    // Delete user
    this.deletingAccount = true
    try {
      await getUser().delete()
    } catch (error) {
      // Requires recent login => redirect to login
      this.handleRequireRecentLogin(error)
      return
    }
  }

  handleRequireRecentLogin(error: any) {
    if (error.code === 'auth/requires-recent-login') {
      this.$router.push({
        name: 'sign-in',
        query: {
          target: 'account',
          reason: 'reauth'
        }
      })
    }
  }

  showSuccessNotification() {
    showNotification({
      title: 'Erfolg',
      text: 'Die Änderungen wurden gespeichert.',
      type: 'success',
      timeout: 10
    })
  }
}
