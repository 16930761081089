


















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Button from '@/components/Button.vue'
import ImagePlaceholder from '@/components/ImagePlaceholder.vue'

@Component({
  components: {
    Button,
    ImagePlaceholder
  }
})
export default class ImageUpload extends Vue {
  @Prop()
  image: string

  @Prop()
  changeButtonText: string

  get fileUploadElement(): any {
    return this.$refs.image_upload
  }

  openFileUpload() {
    this.fileUploadElement.click()
  }

  async fileChanged($event: any) {
    const file: File = this.fileUploadElement.files[0]
    this.imageChanged(file)
  }

  @Emit()
  imageChanged(file: File) {
    // placeholder
  }
}
